import React, { useEffect, useRef, useState } from "react";
import "./ActivationForm.css";
import { Row } from "react-bootstrap";
import { useFormik } from "formik";
import FormInput from "../FormInput/FormInput";
import { sendInstalationID, validateKey } from "../../api/activation";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import InfoMessage from "./InfoMessage";

const ActivationForm = () => {
  const [isError, setIsError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [buttonState, setButtonState] = useState({
    isDisabled: true,
    isLoading: false,
    variant: '',
    message: 'Obține id-ul de confirmare',
  })
  const [recaptcha, setRecaptcha] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState({
    variant: "error",
    message: "",
  });
  const recaptchaRef = useRef();

  const siteKey =
    process.env.REACT_APP_ENV === "production"
      ? process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_PROD
      : process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_DEV;

  const inputs = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

  const input0Ref = useRef(null);
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);
  const input7Ref = useRef(null);
  const input8Ref = useRef(null);

  const inputsRef = [
    input0Ref,
    input1Ref,
    input2Ref,
    input3Ref,
    input4Ref,
    input5Ref,
    input6Ref,
    input7Ref,
    input8Ref,
  ];

  useEffect(() => {
    executeRecaptcha();
  }, []);

  const combineInputs = (inputs) => {
    const inputsToJoin = Object.values(inputs).join("")
    return inputsToJoin;
  };

  const inputSchema = Yup.string()
    .required("Required")
    .matches(/^[0-9]*$/, "Only numbers")
    .min(7, "At least 7 characters");

  const vSchema = Yup.object().shape({
    input0: inputSchema,
    input1: inputSchema,
    input2: inputSchema,
    input3: inputSchema,
    input4: inputSchema,
    input5: inputSchema,
    input6: inputSchema,
    input7: inputSchema,
    input8: inputSchema,
  });

  const formik = useFormik({
    initialValues: {
      input0: "",
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      input6: "",
      input7: "",
      input8: "",
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        const result = await handleSubmit(values);
        if (result) {
          resetForm();
        }
        setIsSubmitted(false);
      } catch (error) {
        setIsError(true);
      }
    },
    validationSchema: vSchema,
  });

  const executeRecaptcha = async () => {
    try {
      const token = await recaptchaRef.current.executeAsync();
      setRecaptcha(token);
      setButtonState((prevState) => ({ ...prevState, isDisabled: false }));
    } catch (err) {
      setConfirmationMessage({ variant: "error", message: 'A apărut o eroare cu Google Recaptcha.' });
      setButtonState((prevState) => ({ ...prevState, isDisabled: true }));
    }
  };

  const handleSubmit = async (values) => {
    try {
      let data = {
        iid: combineInputs(values),
        token: recaptcha,
      };

      // First check if key is valid (10 second call to kich)
      const isValidKey = await validateKey(data);
      if (isValidKey.error) {
        setConfirmationMessage({ variant: 'error', message: isValidKey?.data?.message ?? 'A apărut o eroare. Vă rugăm să încercați din nou.' });
      } else {
        const result = await sendInstalationID(data);

        // Handle the result and update the state
        if (result?.data && !result.data.error) {
          setConfirmationMessage({ variant: 'success', message: result.data.message });
          setButtonState((prevState) => ({ ...prevState, isDisabled: true, message: 'Solicitare trimisă cu success!', variant: 'success' }));
        } else {
          setConfirmationMessage({ variant: 'error', message: result?.data?.message ?? 'A apărut o eroare. Vă rugăm să încercați din nou.' });
        }
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setConfirmationMessage({ variant: 'error', message: 'A apărut o eroare. Vă rugăm să încercați din nou.' });
    } finally {
      setButtonState((prevState) => ({ ...prevState, isLoading: false }));
      // Revert back to the previous state after 3 seconds (3000 milliseconds)
      setTimeout(() => {
        setConfirmationMessage({ variant: 'error', message: '' });
        setButtonState({
          isDisabled: false,
          isLoading: false,
          variant: '',
          message: 'Obține id-ul de confirmare',
        });
      }, 10000); // Adjust the delay as needed (1000 = 1 sec)
      return true;
    }
  };


  return (
    <>
      <form className="formComp" onSubmit={formik.handleSubmit}>
        <Row className="d-flex justify-content-center flex-wrap">
          {inputsRef.map((input, index) => {
            return (
              <FormInput
                key={index}
                index={index}
                input={input}
                inputs={inputs}
                inputsRef={inputsRef}
                formik={formik}
                isSubmitted={isSubmitted}
              />
            );
          })}
        </Row>
        <button
          type="submit"
          disabled={!formik.isValid || buttonState.isLoading || buttonState.isDisabled}
          className={`btn-activare${buttonState.variant ? "-" + buttonState.variant : ""}`}
          onClick={() => {
            setIsSubmitted(true);
            if (formik.dirty && Object.keys(formik.errors).length === 0) {
              setButtonState((prevState) => ({ ...prevState, isLoading: true }));
              formik.submitForm(); // Manually trigger form submission
            }
          }}
        >

          {buttonState.isLoading ? (
            <div style={{ minWidth: '10rem' }}>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </div>
          ) : (
            buttonState.message
          )}
        </button>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={siteKey}
          size="invisible"
        />
        {confirmationMessage && confirmationMessage.message && (
          <InfoMessage
            variant={confirmationMessage.variant}
          >
            {/* Have this be interpreted HTML maybe (only when requested) */}
            {confirmationMessage.message}
          </InfoMessage>
        )}
      </form>
    </>
  );
};

export default ActivationForm;
