import { Button, Col, Form, Row } from "react-bootstrap";

function EmailForm({ handleSubmit }) {
    return (
        <Row className="justify-content-center">
            <Col lg={8}>
                <Row className="justify-content-start mb-5">
                    <Col lg={10}>
                        <img src="/assets/images/core-software-blue.png" className="img-fluid" alt="Core Software Blue" />
                    </Col>
                </Row>
                <Row className="justify-content-start text-left mb-2">
                    <Col lg={10}>
                        <h1 className='sign-title text-left'>👋 Salutări<b>!</b></h1>
                    </Col>
                </Row>
                <Row className="justify-content-start text-left mb-2">
                    <Col lg={10}>
                        <p className='text-left fancy-p'>Urmează să accesezi pagina dedicată activării alternative a licenței Office.</p>
                    </Col>
                </Row>
                <Row className="justify-content-start text-left mb-4">
                    <Col lg={10}>
                        <p className='text-left fancy-p'>Pentru a continua, vă rugăm să vă conectați cu contul dumneavostră.</p>
                    </Col>
                </Row>
                <Form onSubmit={handleSubmit}>
                    <Row className="justify-content-center mb-2">
                        <Col lg={12}>
                            <Row className="justify-content-center mb-2">
                                <Col lg={12}>
                                    <Form.Group className="mb-2" controlId="formEmail">
                                        <Form.Label><b>Email</b></Form.Label>
                                        <Form.Control
                                            type='email'
                                            className='form-control-modern m-auto'
                                            name='email'
                                            placeholder="adresa@email.com"
                                            style={{ borderRadius: '1rem', height: '3.5rem', background: '#F7FBFF', border: '1px solid #D4D7E3', fontFamily: 'Montserrat' }}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-2" controlId="formPassword">
                                        <Form.Label><b>Parolă</b></Form.Label>
                                        <Form.Control
                                            type='password'
                                            className='form-control-modern m-auto'
                                            name='password'
                                            placeholder="******"
                                            style={{ borderRadius: '1rem', height: '3.5rem', background: '#F7FBFF', border: '1px solid #D4D7E3', fontFamily: 'Montserrat' }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={12}>
                                    <Button type='submit' className="btn btn-password-protection w-100 text-center" style={{ borderRadius: '1rem', height: '3.5rem', fontSize: '1.5rem' }}>
                                        Continuă
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    )
}

export default EmailForm;