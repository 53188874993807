import { useEffect, useState } from "react";
import { FaCheckCircle, FaExclamationTriangle, FaInfoCircle } from "react-icons/fa";

const InfoMessage = ({ children, variant = "info" }) => {

    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1399);
        };
        handleResize(); // Check initial screen size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const baseStyles = {
        background: '#E1F5E9',
        padding: '1rem',
        width: isSmallScreen ? '100%' : '75%',
        color: '#1CC15F',
        border: '1px solid #1CC15F',
        borderRadius: '10px',
        display: 'grid',
        gridTemplateColumns: isSmallScreen ? '1fr' : 'auto 1fr',
        gap: isSmallScreen ? '0.5rem' : '1.5rem',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'left',
    };

    const variantStyles = {
        success: {
            background: '#E1F5E9',
            color: '#1CC15F',
            border: '1px solid #1CC15F',
        },
        error: {
            background: '#F8D7DA',
            color: '#721C24',
            border: '1px solid #F5C6CB',
        },
        info: {
            background: '#E7F3FE',
            color: '#31708F',
            border: '1px solid #B3D7F2',
        },
    };

    const icon = {
        info: <FaInfoCircle size={20} />,
        error: <FaExclamationTriangle size={20} />,
        success: <FaCheckCircle size={20} />,
    }

    return (
        <div style={{ ...baseStyles, ...variantStyles[variant] }}>
            <div className="icon-box text-center">
                {icon[variant]}
            </div>
            <div className="text-start">
                {children}
            </div>
        </div>
    )
}

export default InfoMessage;