import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const DividerWithText = ({ text }) => {
  return (
    <Container className="my-4">
      <Row className="align-items-center">
        <Col className='p-0'>
          <hr />
        </Col>
        <Col xs="auto" className="text-center text-muted">
          {text}
        </Col>
        <Col className='p-0'>
          <hr />
        </Col>
      </Row>
    </Container>
  );
};

export default DividerWithText;
