import axios from "axios";

const API_URL = process.env.REACT_APP_API_PUBLIC_URL || "http://localhost:3002/api";

export async function getMaintanancePopUpStatus() {
  const config = {
    method: "get",
    url: `${API_URL}/settings-landing`,
    withCredentials: true,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}