import axios from "axios";
const API_URL = process.env.REACT_APP_API_PUBLIC_URL || "http://localhost:3002/api";

export async function sendInstalationID(data) {
  const config = {
    method: "post",
    url: `${API_URL}/activation`,
    withCredentials: true,
    data: data,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function validateKey(data) {
  const config = {
    method: "post",
    url: `${API_URL}/activation/validate-key`,
    withCredentials: true,
    data: data,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function getToken(data) {
  const config = {
    method: "POST",
    url: `${API_URL}/activation/get-token`,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: data,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function logout() {
  const config = {
    method: "POST",
    url: `${API_URL}/activation/logout`,
    withCredentials: true,
  };
  return axios(config)
    .then((response) => {
      sessionStorage.removeItem('jwt');
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function checkToken() {
  const config = {
    method: 'get',
    url: `${API_URL}/activation/check-token`,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return await axios(config)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error.response;
    });
}
