import React from "react";
import { Col } from "react-bootstrap";
import "./FormInput.css";

const FormInput = ({
  index,
  inputs,
  input,
  inputsRef,
  formik,
  isSubmitted,
}) => {
  return (
    <Col className="formComp">
      <label className="formLabel" htmlFor={`input${index}`}>
        {`Șirul ${inputs[index]}`}
      </label>
      <input
        className="formInput col-lg-12"
        type="text"
        id={`input${index}`}
        name={`input${index}`}
        onChange={formik.handleChange}
        value={formik.values[`input${index}`]}
        placeholder="XXXXXXX"
        maxLength="7"
        onInput={(e) => {
          if (e.target.value.length === 7 && index !== 8) {
            inputsRef[index + 1].current.focus();
          } else if (e.target.value.length === 0 && index !== 0) {
            inputsRef[index - 1].current.focus();
          }
        }}
        ref={input}
      />
      {formik.errors[`input${index}`] ? (
        <span className="error">{formik.errors[`input${index}`]}</span>
      ) : <span className="error"></span>}
    </Col>
  );
};

export default FormInput;
