import { Col, Row, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { FaSearchPlus } from 'react-icons/fa';
import "../css/activation.css"

const ActivationTutorialStep = ({ text, infoImgSrc, imgSrc, children }) => {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    return (
        <>
            <Row className="mb-3 info-pair">
                <Col lg={12} xl={12} xxl={10} className="text-center mb-3 mb-md-0">
                    <div className="info-step">
                        <div className="icon-box text-center">
                            <img src={infoImgSrc} className="img-fluid" alt="Step" />
                        </div>
                        <div className="text-start">
                            {children}
                        </div>
                    </div>
                </Col>
                {imgSrc && (
                    <Col xs={12} md={12} lg={12} xl={12} xxl={2} className="text-center">
                        <div className="info-step-image w-100" onClick={openModal}>
                            <img
                                src={imgSrc}
                                className="img-fluid w-100"
                                alt="Step Image"
                            />
                            <FaSearchPlus className="zoom-icon" />
                        </div>
                    </Col>
                )}
            </Row>
            <Modal show={showModal} onHide={closeModal} centered>
                <Modal.Header closeButton/>
                <img src={imgSrc} className="img-fluid w-100" alt="Enlarged Image" />
            </Modal>
        </>
    );
}

export default ActivationTutorialStep;
