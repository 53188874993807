import { Button, Col, Form, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

function CodeForm({ handleSubmit }) {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get('orderId');

    return (
        <Row className="justify-content-center">
            <Col lg={8}>
                <Row className="justify-content-start mb-5">
                    <Col lg={10}>
                        <img src="/assets/images/core-software-blue.png" className="img-fluid" alt="Core Software Blue" />
                    </Col>
                </Row>
                <Row className="justify-content-start text-left mb-2">
                    <Col lg={10}>
                        <h1 className='sign-title text-left'>👋 Salutări<b>!</b></h1>
                    </Col>
                </Row>
                <Row className="justify-content-start text-left mb-2">
                    <Col lg={10}>
                        <p className='text-left fancy-p'>Bine ai venit pe portalul de activare alternativă a licenței Office prin telefon. </p>
                    </Col>
                </Row>
                <Row className="justify-content-start text-left mb-2">
                    <Col lg={10}>
                        <p className='text-left fancy-p'>Acest portal este pus la dispoziție clienților noștri pentru a efectua activarea într-un mod cât mai rapid și ușor. </p>
                    </Col>
                </Row>
                <Row className="justify-content-start text-left mb-2">
                    <Col lg={10}>
                        <p className='text-left fancy-p'>Utilizând aceast portal nu va fi nevoie să contactați telefonic pe nimeni.</p>
                    </Col>
                </Row>
                <Row className="justify-content-start text-left mb-4">
                    <Col lg={10}>
                        <p className='text-left fancy-p'>Pentru a continua, te rugăm să introduci numărul comenzii tale.</p>
                    </Col>
                </Row>
                <Form onSubmit={handleSubmit}>
                    <Row className="justify-content-center">
                        <Col lg={12}>
                            <Row className="justify-content-center mb-2">
                                <Col lg={12}>
                                    <Form.Group className="mb-2" controlId="formCode">
                                        <Form.Label><b>Nr. Comandă</b></Form.Label>
                                        <Form.Control
                                            type='text'
                                            className='form-control-modern m-auto'
                                            name='code'
                                            placeholder="Introduceți numărul comenzii..."
                                            defaultValue={orderId}
                                            style={{ borderRadius: '1rem', height: '3.5rem', background: '#F7FBFF', border: '1px solid #D4D7E3', fontFamily: 'Montserrat' }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={12}>
                                    <Button type='submit' className="btn btn-password-protection w-100 text-center" style={{ borderRadius: '1rem', height: '3.5rem', fontSize: '1.5rem' }}>
                                        Continuă
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    )
}

export default CodeForm;