import { Alert, Col, Row } from "react-bootstrap";
import { withDismissible } from "../hoc";
import CodeForm from "./forms/CodeForm";
import EmailForm from "./forms/EmailForm";
import GeneralForm from "./forms/GeneralForm";


function PasswordProtection({ handleSubmit, show, message, type = null }) {
    const AlertDismissible = withDismissible(Alert);
    const onFormSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        formData.append("accountType", type);

        const data = {};
        formData.forEach((value, key) => {
            data[key] = value;
        });

        handleSubmit(data);
    }

    return (
        <Row className='justify-content-center align-items-center' style={{ minHeight: '100vh', margin: 'auto' }}>
            <Col xs={12} md={10} lg={12} xl={6} className='d-flex flex-column justify-content-center align-items-center p-4'>
                <div className='center-sign h-full w-100'>
                    {type !== null ? (
                        type === 'comanda' ? (
                            <CodeForm handleSubmit={onFormSubmit} />
                        ) : (
                            <EmailForm handleSubmit={onFormSubmit} />
                        )
                    ) : (
                        <GeneralForm handleSubmit={onFormSubmit} />
                    )}
                    {show && message && (
                        <Row className="justify-content-center align-items-center mt-3 mb-3">
                            <Col lg={9} className="justify-content-center align-items-center">
                                <AlertDismissible variant='danger'>{message}</AlertDismissible>
                            </Col>
                        </Row>
                    )}
                    <Row className="justify-content-center align-items-center">
                        <Col lg={9} className="justify-content-center align-items-center">
                            <p className='text-center justify-content-center align-items-center'>
                                &copy; Copyright {new Date().getFullYear()}. All Rights Reserved.
                            </p>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col xs={12} md={6} lg={6} className='d-none d-xl-flex justify-content-center align-items-center p-0'>
                <img src="/assets/images/activation-image.png" className='img-fluid rounded' style={{ maxHeight: '65%', maxWidth: '65%' }} alt="Activation Image" />
            </Col>
        </Row>
    );
}

export default PasswordProtection;
