import React from 'react';
import { Spinner } from 'react-bootstrap';

const componentStyle = {
    'display': 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    'height': '100%',
    'width': '100%'
}

export default function Loader({ variant = '' }) {
    return (
        <div style={componentStyle}>
            <Spinner animation="border" role="status" variant={variant}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}