import { useState } from "react";
import { Button, Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { logout } from "../../api/activation";
import { BiLogOut } from "react-icons/bi";
import { RxHamburgerMenu } from "react-icons/rx";
const ActivareNavbar = ({ }) => {

    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const navigate = useNavigate();

    const toggleOffcanvas = () => {
        setShowOffcanvas(!showOffcanvas);
    };

    const openNewWindow = (url) => {
        window.open(url);
    };

    const handleLogout = async () => {
        const result = await logout();
        if (result.status === 200) {
            navigate(0)
        }
    }

    return (
        <>
            <Navbar expand="lg" className="header p-2">
                <Container fluid className="d-flex align-items-center">
                    <Navbar.Brand href="#">
                        <img
                            width={230}
                            height={27}
                            src="/assets/images/Core-Software-Logo.png"
                            alt="Core Software Logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="offcanvasNavbar" className="ms-auto custom-toggler" onClick={toggleOffcanvas}><RxHamburgerMenu color="white" /></Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-flex justify-content-end">
                        <Nav>
                            <Nav.Link>
                                <Button
                                    className="rounded"
                                    style={{ backgroundColor: "#F6F6F6", cursor: 'pointer', padding: '0.7rem', color: '#162379', fontSize: '1rem' }}
                                    onClick={() => openNewWindow("https://www.core-software.ro")}
                                >
                                    Vizitează website-ul nostru și vezi toate ofertele!
                                </Button>
                            </Nav.Link>
                            <Nav.Link>
                                <Button
                                    className="rounded"
                                    style={{ backgroundColor: "#F6F6F6", cursor: 'pointer', padding: '0.7rem', color: '#162379', fontSize: '1rem' }}
                                    onClick={handleLogout}
                                >
                                    <BiLogOut /> Log out
                                </Button>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Offcanvas show={showOffcanvas} onHide={toggleOffcanvas} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Meniu principal</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="ml-auto flex-column">
                        <Nav.Link>
                            <Button
                                className="rounded"
                                style={{ backgroundColor: "#F6F6F6", cursor: 'pointer', padding: '0.7rem', color: '#162379', fontSize: '0.7rem' }}
                                onClick={() => openNewWindow("https://www.core-software.ro")}
                            >
                                Vizitează website-ul nostru și vezi toate ofertele!
                            </Button>
                        </Nav.Link>
                        <Nav.Link>
                            <Button
                                className="rounded"
                                style={{ backgroundColor: "#F6F6F6", cursor: 'pointer', padding: '0.7rem', color: '#162379', fontSize: '0.7rem' }}
                                onClick={handleLogout}
                            >
                                <BiLogOut /> Log out
                            </Button>
                        </Nav.Link>
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default ActivareNavbar;