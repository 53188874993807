import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Activation from "./components/pages/activation";


function App() {
    return (
        <React.StrictMode>
            <Router>
                <Routes>
                    <Route path="/">
                        <Route index element={<Activation />} />
                        <Route path="code" element={<Navigate to="/comanda" replace />} />
                        <Route path="comanda" element={<Activation type="comanda" />} />
                        <Route path="email" element={<Activation type="email" />} />
                        <Route path='/*' element={<Activation />} />
                    </Route>
                </Routes>
            </Router>
        </React.StrictMode>
    )
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
