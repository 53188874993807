import React from "react";
import "./StartPopUp.css";
import { Card, Modal } from "react-bootstrap";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const StartPopUp = ({ startPopUpIsOpen, getStartPopUpState, modalData }) => {
  const { title, content } = modalData;


  return (
    <Modal
      show={startPopUpIsOpen}
      backdrop={true}
      keyboard={false}
      onHide={() => getStartPopUpState(!startPopUpIsOpen)}
    >
      <Modal.Header closeButton>
        <h1 className="modal-title" style={{ marginLeft: "1.5rem", flexGrow: '1', textAlign: "center" }}>{title}</h1>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Body className="m-2 p-2">
            <Markdown rehypePlugins={[rehypeRaw]}>
              {`${content}`}
            </Markdown>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default StartPopUp;
